import React from 'react';
import '../App.css';
import customSelect from '../resources/images/custom-select.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';

function Breadcrumb(props) {
    const selectPortfolios = (item) => {
        props.portfolioChange(item.target.value);
    };

    const addPortfolio = () => {
        props.create();
    };

    return (
        <div>
            <div className="page-breadcrumb">
                <div className="row">
                    <div className="col-12 align-self-center">
                        <h3 className="page-title text-truncate text-dark font-weight-medium mb-1">
                            {props.title}
                        </h3>
                        {props.portfolios && (
                            <nav
                                style={{ float: 'right' }}
                                className="navbar navbar-expand-lg navbar-light bg-light"
                            >
                                <select
                                    onChange={selectPortfolios}
                                    id="lgselect"
                                    style={{
                                        background:
                                            'url(' +
                                            customSelect +
                                            ') right 1.1rem center no-repeat',
                                    }}
                                >
                                    {props.portfolios.map((item) => {
                                        return (
                                            <option
                                                value={item._id}
                                                selected={
                                                    props.selectedPortfolio ==
                                                    item._id
                                                }
                                            >
                                                {item.name}
                                            </option>
                                        );
                                    })}
                                </select>
                                <div className="tooltipBtn">
                                    <FontAwesomeIcon
                                        onClick={(e) => addPortfolio()}
                                        style={{
                                            marginLeft: '10px',
                                            fontSize: 'xx-large',
                                            cursor: 'pointer',
                                        }}
                                        icon={faPlusCircle}
                                    />
                                    <span class="tooltiptext">
                                        Ajouter un portfolio
                                    </span>
                                </div>
                            </nav>
                        )}

                        <div className="d-flex align-items-center">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb m-0 p-0">
                                    <li className="breadcrumb-item">
                                        Dashboard
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Breadcrumb;
