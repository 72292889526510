import React from 'react';
import '../App.css';
import Barchart from './barchart';

function DividendesRepartition() {
    return (
        <div className="col-6">
            <div className="card">
                <div className="card-body">
                    <h4 className="card-title">Répartition des Dividendes</h4>
                    <Barchart />
                </div>
            </div>
        </div>
    );
}

export default DividendesRepartition;