import React, { useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { CREATE_PORTFOLIO } from '../graphql/mutations/portfolio';

function NoPortfolio(props) {
    const [createPf, { data }] = useMutation(CREATE_PORTFOLIO);
    const [name, setName] = useState('');

    const create = async () => {
        await createPf({
            variables: {
                name: name,
                userId: props.userId,
            },
        });
        props.close(true);
    };

    const handleName = (e) => {
        setName(e.target.value);
    };

    return (
        <div className="col-12">
            <div
                className="card"
                style={{
                    height: '100vh',
                    width: '100vw',
                    display: 'table-cell',
                    verticalAlign: 'middle',
                    textAlign: 'center',
                }}
            >
                <div className="card-body">
                    <h3>Créer votre premier portfolio</h3>
                    <div className="customize-input">
                        <input
                            value={name}
                            onChange={(e) => handleName(e)}
                            className="form-control custom-radius"
                            type="search"
                            placeholder="Nom du portfolio"
                            aria-label="Search"
                        />
                    </div>

                    <button
                        onClick={(e) => create()}
                        style={{ marginTop: '10px' }}
                        className="btn waves-effect waves-light btn-rounded btn-outline-secondary"
                    >
                        Créer
                    </button>
                </div>
            </div>
        </div>
    );
}

export default NoPortfolio;
