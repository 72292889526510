import React, { useState, useEffect } from 'react';
import '../App.css';
import { useMutation } from '@apollo/react-hooks';
import { EDIT_STOCK_IN_PORTFOLIO } from '../graphql/mutations/portfolio';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

function FormEditStock(props) {
    const [form, setData] = useState({
        ticker: props.ticker,
        shares: props.shares,
    });
    const [editStocks, { data }] = useMutation(EDIT_STOCK_IN_PORTFOLIO);

    const handleStockName = (value) => {
        setData({ ...form, ...{ ticker: value.target.value } });
    };

    const handleForm = (e) => {
        let id = e.target.id;
        let value = e.target.value;

        let formData = form;
        formData[id] = value;
        setData({ ...form, ...formData });
    };

    const btnValidate = async () => {
        await editStocks({
            variables: {
                portfolioId: props.portfolioId,
                shares: parseInt(form.shares),
                ticker: form.ticker,
            },
        });
        props.close();
    };

    const closeForm = () => {
        props.close();
    };

    return (
        <div className="col-12">
            <div className="card">
                <div className="card-body">
                    <div className="card-title">
                        <h4>
                            <FontAwesomeIcon
                                icon={faArrowLeft}
                                style={{
                                    marginRight: '10px',
                                    cursor: 'pointer',
                                }}
                                onClick={(e) => closeForm()}
                            />
                            Editition du fond {form.ticker}
                        </h4>
                    </div>

                    <form className="text-center">
                        <div className="customize-input">
                            <input
                                value={form ? form.ticker : ''}
                                className="form-control custom-radius"
                                type="search"
                                placeholder="Ticker du fond"
                                aria-label="Search"
                                onChange={(e) => handleStockName(e)}
                            />
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                className="feather feather-search form-control-icon"
                            >
                                <circle cx="11" cy="11" r="8"></circle>
                                <line
                                    x1="21"
                                    y1="21"
                                    x2="16.65"
                                    y2="16.65"
                                ></line>
                            </svg>
                        </div>
                        <br />
                        <div
                            style={{
                                display: form.ticker.length > 0 ? null : 'none',
                            }}
                        >
                            <input
                                id="shares"
                                onChange={(e) => handleForm(e)}
                                placeholder="Nombre de part"
                                value={form ? form.shares : ''}
                                type="text"
                                style={{ paddingLeft: '5px' }}
                            />
                        </div>
                        <br />
                        <div
                            style={{
                                textAlign: 'center',
                                display: form.ticker.length > 0 ? null : 'none',
                            }}
                        >
                            <button
                                type="button"
                                onClick={(e) => btnValidate()}
                                className="btn waves-effect waves-light btn-rounded btn-outline-secondary"
                            >
                                Valider
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default FormEditStock;
