import React from 'react';
import '../App.css';

function Sidebar() {
    return (
        <nav id="sidebar">
            <div className="sidebar-header">
                <h3>DivNet</h3>
            </div>
            <li className="list-divider"></li>
            <ul className="list-unstyled components">
                <li className="active">
                    <a>Portfolio</a>
                </li>
                <li className="">
                    <a>Test</a>
                </li>
                <li className="">
                    <a>Test</a>
                </li>
                <li className="">
                    <a>Test</a>
                </li>
            </ul>
        </nav>
    );
}

export default Sidebar;
