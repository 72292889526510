import React, { useState, useEffect } from 'react';
import '../App.css';
import Piechart from './piechart';

function PortfolioRepartition(props) {
    const [stocks, setStocks] = useState(null);

    useEffect(() => {
        if (props.stocks) {
            formatData();
        }
    }, [props.stocks]);

    const getRandomColor = () => {
        var letters = '0123456789ABCDEF'.split('');
        var color = '#';
        for (var i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
    }

    const formatData = () => {
        let data = { datasets: [], labels: [] };
        let datasets = [];
        let colors = []
        props.stocks.map((item) => {
            datasets.push(item.user_data.total_amount);
            data.labels.push(item.ticker);
            colors.push(getRandomColor());
        });
        data.datasets.push({ data: datasets, backgroundColor: colors });

        setStocks(data);
    };

    return (
        <div className="col-6">
            <div className="card">
                <div className="card-body">
                    <h4 className="card-title">Répartition des fonds</h4>
                    <Piechart data={stocks} />
                </div>
            </div>
        </div>
    );
}

export default PortfolioRepartition;