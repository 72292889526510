import gql from 'graphql-tag';

export const GET_USER_BY_ID = gql`
    query userbyid($id:ID!) {
        userById(id: $id) {
            _id
            email
            portfolios {
                _id
                name
            }
        }
    }
`;
