import React, { useState, useEffect } from 'react';
import '../App.css';
import Breadcrumb from './breadcrumb';
import Tablestocks from './tablestocks';
import PortfolioRepartition from './portfoliorepartition';
import Groupdata from './groupdata';
import DividendesRepartition from './dividendesrepartition';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { GET_PORTFOLIO_BY_ID } from '../graphql/querys/portfolios';
import { DELETE_STOCK_IN_PORTFOLIO } from '../graphql/mutations/portfolio';
import FormAddStock from './formAddStock';
import FormEditStock from './formEditStock';

function Dashboard(props) {
    const [form, setForm] = useState({ type: null, show: false });
    const [formEdit, setFormEdit] = useState({ ticker: '', shares: '' });

    const {
        loading: loading_pf,
        error: error_pf,
        data: data_pf,
        refetch: refetch_pf,
    } = useQuery(GET_PORTFOLIO_BY_ID, {
        variables: {
            id: props.portfolio_id,
        },
    });

    const [deleteStocksMutation, { data: data_deleteStock }] = useMutation(
        DELETE_STOCK_IN_PORTFOLIO
    );

    const addStock = (e) => {
        setForm({ type: 'addStock', show: true });
    };

    const closeForm = () => {
        refetch_pf();
        setForm({ type: null, show: false });
    };

    const editStock = (stock) => {
        setFormEdit({ ticker: stock.ticker, shares: stock.shares });
        setForm({ type: 'editStock', show: true });
    };
    const deleteStock = async (ticker) => {
        await deleteStocksMutation({
            variables: {
                portfolioId: props.portfolio_id,
                ticker: ticker,
            },
        });

        refetch_pf();
    };

    const portfolioChange = (item) => {
        props.portfolioChange(item);
    };

    const createPortfolio = () => {
        props.createPortfolio();
    };

    return (
        <div>
            <Breadcrumb
                title={data_pf && data_pf.portfolioById.name}
                portfolios={props.portfolios}
                selectedPortfolio={props.portfolio_id}
                portfolioChange={(e) => portfolioChange(e)}
                create={(e) => createPortfolio()}
            />
            <div
                style={{ display: form.show && 'none' }}
                className="container-fluid"
            >
                <Groupdata
                    nbStocks={data_pf && data_pf.portfolioById.stocks.length}
                    amount={data_pf && data_pf.portfolioById.amount}
                />
                <div className="row">
                    <Tablestocks
                        stocks={data_pf && data_pf.portfolioById.stocks}
                        addStock={(e) => addStock(e)}
                        editStock={(e) => editStock(e)}
                        deleteStock={(e) => deleteStock(e)}
                    />
                    <PortfolioRepartition
                        stocks={data_pf && data_pf.portfolioById.stocks}
                    />
                    <DividendesRepartition />
                </div>
            </div>
            <div
                style={{ display: !form.show && 'none' }}
                id="main-form"
                className="container-fluid"
            >
                {form.type === 'addStock' && (
                    <FormAddStock
                        close={(e) => closeForm(e)}
                        portfolioId={props.portfolio_id}
                    />
                )}
                {form.type === 'editStock' && (
                    <FormEditStock
                        close={(e) => closeForm(e)}
                        ticker={formEdit.ticker}
                        shares={formEdit.shares}
                        portfolioId={props.portfolio_id}
                    />
                )}
            </div>
        </div>
    );
}

export default Dashboard;
