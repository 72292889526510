import React from 'react';
import { Router, Route } from 'react-router-dom';
import history from './history';
import App from '../App';
import Home from '../views/Home';
const Routes = () => {
    return (
        <Router history={history}>
            <Route exact path="/" component={App} />
            <Route exact path="/home" component={Home} />
        </Router>
    );
};

export default Routes;