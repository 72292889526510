import React from 'react';
import '../App.css';
import customSelect from '../resources/images/custom-select.png';

function Topbar() {
    return (
        <nav className="navbar navbar-expand-lg navbar-light bg-light">
            <select id="lgselect" style={{ background: "url(" + customSelect + ") right 1.1rem center no-repeat" }}>
                <option>FR</option>
            </select>
        </nav>
    );
}

export default Topbar;