import React, { useState, useEffect } from 'react';
import Chart from 'chart.js'
import '../App.css';
import Utils from '../utils/utils';

function Barchart() {
    const [id, setId] = useState(null);
    useEffect(() => {
        if (!id) {
            setId(Utils.guidGenerator());
        }
        else {
            buildChart();
        }
    }, [id]);

    const buildChart = () => {
        let ctx = document.getElementById(id).getContext('2d');
        let data = {
            datasets: [{
                barPercentage: 0.5,
                barThickness: 6,
                maxBarThickness: 8,
                minBarLength: 2,
                data: [10, 20, 30, 40, 50, 60, 70]
            }], labels: [
                'Red',
                'Yellow',
                'Blue',
                "Green",
                "Black",
                "Brown",
                "Purple"
            ]
        };
        let myBarChart = new Chart(ctx, {
            type: 'bar',
            data: data,
            options: {}
        });
    }

    return (
        <div>
            <canvas id={id} />
        </div>
    );
}

export default Barchart;