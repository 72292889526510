import gql from 'graphql-tag';

export const CREATE_PORTFOLIO = gql`
    mutation createPortfolio($name: String!, $userId: ID!) {
        createPortfolio(inputs: { name: $name, userId: $userId })
    }
`;

export const ADD_STOCK_IN_PORTFOLIO = gql`
    mutation addStockInPortfolio(
        $ticker: String!
        $shares: Int!
        $portfolioId: ID!
    ) {
        addStockInPortfolio(
            inputs: {
                ticker: $ticker
                shares: $shares
                portfolioId: $portfolioId
            }
        )
    }
`;

export const DELETE_STOCK_IN_PORTFOLIO = gql`
    mutation removeStockInPortfolio($ticker: String!, $portfolioId: ID!) {
        removeStockInPortfolio(
            inputs: { ticker: $ticker, portfolioId: $portfolioId }
        )
    }
`;

export const EDIT_STOCK_IN_PORTFOLIO = gql`
    mutation editStockInPortfolio(
        $ticker: String!
        $shares: Int!
        $portfolioId: ID!
    ) {
        editStockInPortfolio(
            inputs: {
                ticker: $ticker
                shares: $shares
                portfolioId: $portfolioId
            }
        )
    }
`;
