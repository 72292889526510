import React, { useState } from 'react';
import '../App.css';
import { useMutation } from '@apollo/react-hooks';
import { ADD_STOCK_IN_PORTFOLIO } from '../graphql/mutations/portfolio';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

function FormAddStock(props) {
    const [form, setData] = useState({ ticker: '', shares: '', chkNew: false });
    const [addStocks, { data }] = useMutation(ADD_STOCK_IN_PORTFOLIO);

    const handleStockName = (value) => {
        setData({ ...form, ...{ ticker: value.target.value } });
    };

    const handleForm = (e) => {
        let id = e.target.id;
        let value = e.target.value;

        let formData = form;
        formData[id] = value;
        setData({ ...form, ...formData });
    };

    const handleCheckbox = (e) => {
        setData({ ...form, ...{ chkNew: form.chkNew ? false : true } });
    };

    const btnValidate = async () => {
        await addStocks({
            variables: {
                portfolioId: props.portfolioId,
                shares: parseInt(form.shares),
                ticker: form.ticker,
            },
        });
        if (form.chkNew) {
            setData({ ...form, ...{ ticker: '', shares: '' } });
        } else {
            props.close();
        }
    };

    const closeForm = () => {
        props.close();
    };

    return (
        <div className="col-12">
            <div className="card">
                <div className="card-body">
                    <div className="card-title">
                        <h4>
                            <FontAwesomeIcon
                                icon={faArrowLeft}
                                style={{
                                    marginRight: '10px',
                                    cursor: 'pointer',
                                }}
                                onClick={(e) => closeForm()}
                            />
                            Ajout d'un nouveau fond à la liste
                        </h4>
                    </div>

                    <form className="text-center">
                        <div className="customize-input">
                            <input
                                value={form.ticker}
                                className="form-control custom-radius"
                                type="search"
                                placeholder="Ticker du fond"
                                aria-label="Search"
                                onChange={(e) => handleStockName(e)}
                            />
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                className="feather feather-search form-control-icon"
                            >
                                <circle cx="11" cy="11" r="8"></circle>
                                <line
                                    x1="21"
                                    y1="21"
                                    x2="16.65"
                                    y2="16.65"
                                ></line>
                            </svg>
                        </div>
                        <br />
                        <div
                            style={{
                                display: form.ticker.length > 0 ? null : 'none',
                            }}
                        >
                            <input
                                id="shares"
                                onChange={(e) => handleForm(e)}
                                placeholder="Nombre de part"
                                value={form.shares}
                                type="text"
                                style={{ paddingLeft: '5px' }}
                            />
                        </div>
                        <br />
                        <div
                            style={{
                                textAlign: 'center',
                                display: form.ticker.length > 0 ? null : 'none',
                            }}
                        >
                            <button
                                type="button"
                                onClick={(e) => btnValidate()}
                                className="btn waves-effect waves-light btn-rounded btn-outline-secondary"
                            >
                                Valider
                            </button>
                            <label>
                                <input
                                    id="chkNew"
                                    type="checkbox"
                                    value={form.chkNew}
                                    onClick={(e) => handleCheckbox(e)}
                                    style={{ marginLeft: '5px' }}
                                />
                                <span style={{ marginLeft: '5px' }}>
                                    Ajouter un nouveau fond
                                </span>
                            </label>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default FormAddStock;
