import gql from 'graphql-tag';

export const GET_PORTFOLIO_BY_ID = gql`
    query portfoliobyid($id: ID!) {
        portfolioById(id: $id) {
            _id
            name
            amount {
                total_amount
                annual_dividende
                percent_annual_dividende
            }
            stocks {
                ticker
                company
                annual_dividende
                percent_dividende
                price
                user_data {
                    shares
                    annual_income
                    total_amount
                }
            }
        }
    }
`;