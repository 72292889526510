import React from 'react';
import '../App.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';

function TableStocks(props) {
    const addStock = () => {
        props.addStock('click');
    };

    const editStock = (ticker, shares) => {
        props.editStock({ ticker: ticker, shares: shares });
    };

    const deleteStock = (ticker) => {
        props.deleteStock(ticker);
    };

    const Tbody = () => {
        return props.stocks
            ? props.stocks.map((item) => {
                  return (
                      <tr>
                          <td>{item.ticker}</td>
                          <td>{item.company}</td>
                          <td>{item.user_data.shares}</td>
                          <td>{item.price}</td>
                          <td>{item.user_data.total_amount}</td>
                          <td>{item.annual_dividende}</td>
                          <td>{item.percent_dividende}</td>
                          <td>{item.user_data.annual_income}</td>
                          <td>
                              <FontAwesomeIcon
                                  icon={faEdit}
                                  style={{
                                      marginRight: '10px',
                                      cursor: 'pointer',
                                  }}
                                  onClick={(e) =>
                                      editStock(
                                          item.ticker,
                                          item.user_data.shares
                                      )
                                  }
                              />
                              <FontAwesomeIcon
                                  icon={faTrash}
                                  style={{
                                      marginLeft: '10px',
                                      cursor: 'pointer',
                                  }}
                                  onClick={(e) => deleteStock(item.ticker)}
                              />
                          </td>
                      </tr>
                  );
              })
            : null;
    };
    return (
        <div className="col-12">
            <div className="card">
                <div className="card-body">
                    <h4 className="card-title">
                        Fonds{' '}
                        <button
                            onClick={() => addStock()}
                            style={{ marginLeft: '50px' }}
                            className="btn waves-effect waves-light btn-rounded btn-outline-secondary"
                        >
                            Ajouter un fond
                        </button>
                    </h4>
                    <div className="table-responsive">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th scope="col">Ticker</th>
                                    <th scope="col">Company</th>
                                    <th scope="col">Quantité</th>
                                    <th scope="col">Prix</th>
                                    <th scope="col">Total Valeur</th>
                                    <th scope="col">Dividende Annuel</th>
                                    <th scope="col">Dividende %</th>
                                    <th scope="col">Argent Annuel</th>
                                    <th scope="col"></th>
                                </tr>
                            </thead>
                            <tbody>
                                <Tbody />
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TableStocks;
