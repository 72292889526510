import React, { useState, useEffect } from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { GET_USER_BY_ID } from '../graphql/querys/users';
import Dashboard from '../components/dashboard';
import Siderbar from '../components/sidebar';
import Topbar from '../components/topbar';
import NoPortfolio from '../components/noportfolio';
import createPortfolio from '../components/createportfolio';
import CreatePortfolio from '../components/createportfolio';

function Home() {
    const {
        loading: loading_client,
        error: error_client,
        data: data_client,
        refetch: refetch_client,
    } = useQuery(GET_USER_BY_ID, {
        variables: {
            id: '5ecfe86b3b4f5e2018d6c43e',
        },
    });

    const [portfolioId, setPortfolioId] = useState(null);
    const [createPortfolioState, setCreatePortfolio] = useState(false);

    useEffect(() => {
        if (data_client) {
            if (data_client.userById.portfolios.length > 0) {
                setPortfolioId(data_client.userById.portfolios[0]._id);
            }
        }
    }, [data_client]);

    const changePortfolio = (item) => {
        setPortfolioId(item);
    };

    const createPortfolio = () => {
        alert('Création');
        setCreatePortfolio(false);
    };

    const showCreatePortfolio = (e) => {
        setCreatePortfolio(true);
    };

    const closeCreate = (e) => {
        setCreatePortfolio(false);
        if (e) {
            refetch_client();
        }
    };

    return (
        <div className="wrapper">
            <Siderbar />
            <div className="main">
                <Topbar />
                <li id="divider_topbar" className="list-divider"></li>
                <div className="content">
                    {portfolioId && !createPortfolioState && (
                        <Dashboard
                            createPortfolio={(e) => showCreatePortfolio()}
                            portfolios={data_client.userById.portfolios}
                            portfolio_id={portfolioId}
                            portfolioChange={(e) => changePortfolio(e)}
                        />
                    )}
                    {data_client &&
                    data_client.userById.portfolios.length == 0 ? (
                        <NoPortfolio
                            close={(e) => closeCreate(e)}
                            userId={data_client.userById._id}
                        />
                    ) : null}
                    {createPortfolioState && (
                        <CreatePortfolio
                            close={(e) => closeCreate(e)}
                            userId={data_client.userById._id}
                        />
                    )}
                </div>
            </div>
        </div>
    );
}

export default Home;
