import React, { useState, useEffect } from 'react';
import Chart from 'chart.js'
import '../App.css';
import Utils from '../utils/utils';

function Piechart(props) {
    const [id, setId] = useState(null);

    useEffect(() => {
        if (id) {
            buildChart();
        }
    }, [id]);

    useEffect(() => {
        if (props.data && !id) {
            setId(Utils.guidGenerator())
        }
    }, [props.data])

   
    const buildChart = () => {
        let ctx = document.getElementById(id).getContext('2d');
        let myPieChart = new Chart(ctx, {
            type: 'pie',
            data: props.data && props.data,
            options: {}
        });
    }

    return (
        <div>
            <canvas id={id} />
        </div>
    );
}

export default Piechart;