import React from 'react';

function Groupdata(props) {
    return (
        <div className="card-group" style={{paddingBottom:"15px"}}>
            <div className="card border-right">
                <div className="card-body">
                    <div className="d-flex d-lg-flex d-md-block align-items-center">
                        <div>
                            <div className="d-inline-flex align-items-center">
                                <h2 className="text-dark mb-1 font-weight-medium">{props.nbStocks}</h2>
                                {/* <span className="badge bg-primary font-12 text-white font-weight-medium badge-pill ml-2 d-lg-block d-md-none">+18.33%</span> */}
                            </div>
                            <h6 className="text-muted font-weight-normal mb-0 w-100 text-truncate">Nombre de fonds</h6>
                        </div>
                        <div className="ml-auto mt-md-3 mt-lg-0">
                            <span className="opacity-7 text-muted"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-user-plus"><path d="M16 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path><circle cx="8.5" cy="7" r="4"></circle><line x1="20" y1="8" x2="20" y2="14"></line><line x1="23" y1="11" x2="17" y2="11"></line></svg></span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="card border-right">
                <div className="card-body">
                    <div className="d-flex d-lg-flex d-md-block align-items-center">
                        <div>
                            <h2 className="text-dark mb-1 w-100 text-truncate font-weight-medium"><sup className="set-doller">$</sup>{props.amount && props.amount.total_amount}</h2>
                            <h6 className="text-muted font-weight-normal mb-0 w-100 text-truncate">Montant total
                                    </h6>
                        </div>
                        <div className="ml-auto mt-md-3 mt-lg-0">
                            <span className="opacity-7 text-muted"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-dollar-sign"><line x1="12" y1="1" x2="12" y2="23"></line><path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path></svg></span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="card border-right">
                <div className="card-body">
                    <div className="d-flex d-lg-flex d-md-block align-items-center">
                        <div>
                            <h2 className="text-dark mb-1 w-100 text-truncate font-weight-medium"><sup className="set-doller">$</sup>{props.amount && props.amount.annual_dividende}</h2>
                            <h6 className="text-muted font-weight-normal mb-0 w-100 text-truncate">Dividendes annuel
                                    </h6>
                        </div>
                        <div className="ml-auto mt-md-3 mt-lg-0">
                            <span className="opacity-7 text-muted"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-dollar-sign"><line x1="12" y1="1" x2="12" y2="23"></line><path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path></svg></span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="card">
                <div className="card-body">
                    <div className="d-flex d-lg-flex d-md-block align-items-center">
                        <div>
                            <h2 className="text-dark mb-1 w-100 text-truncate font-weight-medium"><sup className="set-doller">%</sup>{props.amount && props.amount.percent_annual_dividende}</h2>
                            <h6 className="text-muted font-weight-normal mb-0 w-100 text-truncate">Dividendes annuel
                                    </h6>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}

export default Groupdata;